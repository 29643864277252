<template>
  <div class="trip-new">
    <div class="font-weight-bold mb-3">
      <router-link class="disabled--text" to="/trips">Trips</router-link>
      <v-icon color="disabled" class="text-h6">mdi-chevron-right</v-icon>
      <span class="black--text">Create new trip</span>
    </div>
    <v-card outlined>
      <div class="pl-lg-9 px-sm-7 pt-sm-7 pt-3 px-3 item-content">
        <div class="text-h6 font-weight-bold">Create new trip</div>
        <div class="text--text mb-5">Provide general information about trip.</div>
        <div class="font-weight-bold mb-3">Trip type</div>
        <v-select
          class="field46"
          v-model="data.type"
          placeholder="Select type"
          :error-messages="typeErrors"
          :items="itemsTypes"
          item-text="name_en"
          item-value="id"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-select>
        <div class="font-weight-bold mb-3">Trip subtype</div>
        <v-select
          class="field46"
          v-model="data.subtype"
          placeholder="Select subtype"
          :error-messages="subtypeErrors"
          :items="itemsSubtypes"
          item-text="title"
          item-value="name"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-select>
        <div class="font-weight-bold mb-3">Locality</div>
        <v-select
          class="field46"
          v-model="data.locality"
          placeholder="Select locality"
          :error-messages="localityErrors"
          :items="itemsLocality"
          item-text="title"
          item-value="name"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-select>
        <div class="font-weight-bold mb-3">Category</div>
        <v-select
          class="field46"
          v-model="data.category"
          placeholder="Select category"
          :error-messages="categoryErrors"
          :items="itemsCategory"
          item-text="title_en"
          item-value="id"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-select>
        <div class="font-weight-bold mb-3">Trip Organizer</div>
        <v-select
          class="field46"
          v-model="data.organizer"
          placeholder="Select organizer"
          :error-messages="orgErrors"
          :items="itemsOrganiz"
          item-text="organizer.name"
          item-value="id"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-select>
        <div class="font-weight-bold mb-3">Departure country/city</div>
        <div class="d-flex flex-md-row flex-column">
          <v-autocomplete
            class="field46 me-md-3"
            v-model="data.departure_country"
            @change="getCity"
            placeholder="Country"
            :items="countryList"
            item-text="name"
            item-value="id"
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-autocomplete>
          <v-autocomplete
            class="field46 mt-sm-0 mt-n2 ms-md-3"
            v-model="data.departure_city"
            placeholder="City"
            :error-messages="depCityErrors"
            :items="cityList"
            item-text="name"
            item-value="id"
            outlined
            dense
            required
            color="primary"
            height="46"
            :readonly="!data.departure_country"
          ></v-autocomplete>
        </div>
        <v-textarea
          class="mt-n2"
          v-model="data.departure_address"
          placeholder="Address"
          :error-messages="depAddressErrors"
          auto-grow
          rows="3"
          row-height="30"
          outlined
          dense
          required
          color="primary"
        ></v-textarea>
        <div class="font-weight-bold mb-3">Destination country/city</div>
        <draggable :list="data.destinations" @change="changeOrder($event)" handle=".handle">
          <div v-for="(item, i) in data.destinations" :key="i">
            <div class="d-flex flex-md-row flex-column align-md-center" style="max-width: 766px" v-if="i < 10">
              <div class="d-flex align-center width100 v-input me-md-3">
                <v-card
                  width="20"
                  min-width="20"
                  height="20"
                  flat
                  v-if="data.destinations.length - 1 != i"
                  class="d-flex align-center justify-center handle move-icon me-2 mb-6"
                >
                  <img class="d-block" src="@/assets/icon/move.svg" alt="move" />
                </v-card>
                <v-autocomplete
                  class="field46"
                  v-model="item.country"
                  @change="getCityTwo(item.country.id, i)"
                  placeholder="Country"
                  :items="countryList"
                  item-text="name"
                  item-value="id"
                  return-object
                  outlined
                  dense
                  required
                  color="primary"
                  height="46"
                ></v-autocomplete>
              </div>
              <div class="d-flex align-center width100 v-input ms-md-3">
                <v-btn v-if="data.destinations.length - 1 != i" class="d-md-none me-2 mb-7" @click="removeItem(i)" x-small icon>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-autocomplete
                  class="field46 mt-sm-0 mt-n2"
                  v-model="item.city"
                  placeholder="City"
                  :error-messages="desCityErrors"
                  @change="i + 1 == data.destinations.length ? data.destinations.push({ city: {}, country: {} }) : ''"
                  :items="townList[i]"
                  item-text="name"
                  item-value="id"
                  return-object
                  outlined
                  dense
                  required
                  color="primary"
                  height="46"
                  :readonly="!item.country.id"
                ></v-autocomplete>
                <v-btn v-if="data.destinations.length - 1 != i" class="d-md-block d-none mb-6 ms-2" @click="removeItem(i)" x-small icon>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </draggable>
        <div class="font-weight-bold mb-3">Availability dates</div>
        <div class="d-flex flex-md-row flex-column">
          <vc-date-picker
            locale="en"
            :input-debounce="500"
            v-model="data.available_since"
            :masks="masks"
            :model-config="modelConfig"
            :first-day-of-week="2"
            is-required
            color="blue"
            class="v-input width100 me-md-3"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <v-text-field
                class="field46"
                :value="inputValue"
                v-on="inputEvents"
                placeholder="From"
                :error-messages="fromErrors"
                outlined
                dense
                color="primary"
                height="46"
                readonly
              >
                <template v-slot:append>
                  <v-img class="mt-2" src="@/assets/icon/calendar.svg"></v-img>
                </template>
              </v-text-field>
            </template>
          </vc-date-picker>
          <vc-date-picker
            locale="en"
            :input-debounce="500"
            v-model="data.available_till"
            :masks="masks"
            :model-config="modelConfig"
            :first-day-of-week="2"
            is-required
            color="blue"
            class="v-input width100 mt-sm-0 mt-n2 ms-md-3"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <v-text-field
                class="field46"
                :value="inputValue"
                v-on="inputEvents"
                placeholder="To"
                :error-messages="toErrors"
                outlined
                dense
                color="primary"
                height="46"
                readonly
              >
                <template v-slot:append>
                  <v-img class="mt-2" src="@/assets/icon/calendar.svg"></v-img>
                </template>
              </v-text-field>
            </template>
          </vc-date-picker>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="pl-lg-9 px-sm-7 pt-sm-7 pt-3 px-3 item-content">
        <div class="text-h6 font-weight-bold">Description</div>
        <div class="text--text mb-3">Provide the information in English and Arabic</div>
        <div class="d-flex flex-md-row flex-column multi-direct">
          <v-text-field
            class="field46 width100 me-md-3"
            v-model="data.title_en"
            placeholder="Title"
            :error-messages="desTitleEnErrors"
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-text-field>
          <v-text-field
            class="field46 width100 mt-md-0 mt-n2 ms-md-3"
            v-model="data.title_ar"
            placeholder="عنوان"
            :error-messages="desTitleArErrors"
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-text-field>
        </div>
        <div class="d-flex flex-md-row flex-column mt-n2 multi-direct">
          <v-text-field
            class="field46 width100 me-md-3"
            v-model="data.short_description_en"
            placeholder="Short description"
            :error-messages="desShortEnErrors"
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-text-field>
          <v-text-field
            class="field46 width100 mt-md-0 mt-n2 ms-md-3"
            v-model="data.short_description_ar"
            placeholder="وصف قصير"
            :error-messages="desShortArErrors"
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-text-field>
        </div>
        <div class="d-flex flex-md-row flex-column mt-n2 multi-direct">
          <v-textarea
            class="width100 me-md-3"
            v-model="data.description_en"
            placeholder="Description"
            :error-messages="desDesEnErrors"
            auto-grow
            rows="4"
            row-height="30"
            outlined
            dense
            required
            color="primary"
          ></v-textarea>
          <v-textarea
            class="width100 mt-md-0 mt-n2 ms-md-3"
            v-model="data.description_ar"
            placeholder="وصف كامل"
            :error-messages="desDesArErrors"
            auto-grow
            rows="4"
            row-height="30"
            outlined
            dense
            required
            color="primary"
          ></v-textarea>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="pl-lg-9 pa-sm-7 pa-3 item-content">
        <div class="text-h6 font-weight-bold">VAT/Service fee</div>
        <div class="text--text mb-3">Provide VAT and Service fee options</div>
        <div class="d-flex align-md-center flex-md-row flex-column">
          <div class="v-input d-block width100 me-md-3">
            <div class="font-weight-bold mb-3">VAT type</div>
            <v-select
              class="field46"
              v-model="data.vat_option"
              placeholder="Select type"
              :error-messages="vatOptionEnErrors"
              :items="itemsVat"
              item-text="title"
              item-value="id"
              outlined
              dense
              required
              color="primary"
              height="46"
            ></v-select>
          </div>
          <div class="v-input width100 mt-md-0 mt-n2 ms-md-3">
            <v-checkbox class="py-2 mt-3" color="success" v-model="data.vat_included" hide-details>
              <template v-slot:label>
                <div class="ms-2">
                  <div class="black--text">VAT included in price</div>
                </div>
              </template>
            </v-checkbox>
          </div>
        </div>
        <div class="d-flex align-md-center flex-md-row flex-column">
          <div class="v-input d-block width100 me-md-3">
            <div class="font-weight-bold mb-3">Service fee type <span class="disabled--text font-weight-regular">(optional)</span></div>
            <v-select
              class="field46"
              v-model="data.service_fee_option"
              placeholder="Select type"
              :error-messages="serviceFeeOptionEnErrors"
              :items="itemsServiceFee"
              item-text="title"
              item-value="id"
              outlined
              dense
              required
              color="primary"
              height="46"
            ></v-select>
          </div>
          <div class="v-input width100 mt-md-0 mt-n2 ms-md-3">
            <v-checkbox class="py-2 mt-3" color="success" v-model="data.service_fee_included" hide-details>
              <template v-slot:label>
                <div class="ms-2">
                  <div class="black--text">Service fee included in price</div>
                </div>
              </template>
            </v-checkbox>
          </div>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="text-end py-3 px-sm-10 px-3">
        <v-btn @click="createItem" depressed color="primary" large width="136">Create</v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  components: {
    draggable,
  },
  data() {
    return {
      data: { destinations: [{ city: {}, country: {} }], vat_included: false, service_fee_included: false },
      idx: null,
      masks: {
        input: 'MMM D, YYYY',
      },
      modelConfig: {
        type: 'number',
        mask: 'iso',
      },
      itemsSubtypes: [
        { name: 'regular', title: 'Regular' },
        { name: 'private', title: 'Private' },
        { name: 'surprise', title: 'Surprise' },
      ],
      itemsLocality: [
        { name: 'local', title: 'Local' },
        { name: 'international', title: 'International' },
      ],
      townList: [],
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getCountryList');
    this.$store.dispatch('getTripTypes');
    this.$store.dispatch('getTripCategory');
    this.$store.dispatch('getTripOrganizers');
    this.$store.dispatch('getSettingsVat', '?all=true');
    this.$store.dispatch('getSettingsServiceFee', '?all=true');
  },
  methods: {
    notifi(btn) {
      if (btn == 'done') {
        this.$notify({
          title: 'Done!',
          message: 'Trip was successfully created',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'error') {
        this.$notify({
          title: 'Something went wrong!',
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    changeOrder(e) {
      const arr = this.townList.splice(e.moved.oldIndex, 1);
      this.townList.splice(e.moved.newIndex, 0, ...arr);
    },
    removeItem(i) {
      this.data.destinations.splice(i, 1);
      this.townList.splice(i, 1);
    },
    async getCity() {
      await this.$store.dispatch('getCityList', this.data.departure_country);
    },
    async getCityTwo(i, idx) {
      this.townList.splice(idx, 1);
      this.idx = idx;
      await this.$store.dispatch('getCityListTwo', i, idx);
    },
    async createItem() {
      this.error = [];
      if (this.data.destinations.length >= 0) {
        const array = [];
        for (let i = 0; i < this.data.destinations.length; i++) {
          if (this.data.destinations[i].city.id) {
            array.push(this.data.destinations[i].city.id);
          }
        }
        this.data.destination_cities = array;
      }
      await this.$store
        .dispatch('createTripsItem', this.data)
        .then(() => {
          this.notifi('done');
        })
        .catch((e) => {
          this.error = e.response.data.error;
          e.response.status > 401 ? this.notifi('error') : '';
        });
    },
  },
  watch: {
    cityListTwo(val) {
      if (this.idx != null) {
        this.townList.splice(this.idx, 0, val);
        this.idx = null;
      } else if (val) {
        this.townList.push(val);
      }
    },
  },
  computed: {
    countryList() {
      return this.$store.getters.countryList;
    },
    cityList() {
      return this.$store.getters.cityList;
    },
    cityListTwo() {
      return this.$store.getters.cityListTwo;
    },
    itemsTypes() {
      return this.$store.getters.tripTypes;
    },
    itemsOrganiz() {
      return this.$store.getters.tripOrganiz;
    },
    itemsCategory() {
      return this.$store.getters.tripCategory;
    },
    itemsVat() {
      return this.$store.getters.settingsVat;
    },
    itemsServiceFee() {
      return this.$store.getters.settingsServiceFee;
    },
    typeErrors() {
      const errors = [];
      this.error.find((item) => item == 'type__required') && errors.push('Please select trip type');
      this.error.find((item) => item == 'type__invalid') && errors.push('Selected trip type is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    subtypeErrors() {
      const errors = [];
      this.error.find((item) => item == 'subtype__required') && errors.push('Please select trip subtype');
      this.error.find((item) => item == 'subtype__invalid') && errors.push('Selected trip subtype is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    localityErrors() {
      const errors = [];
      this.error.find((item) => item == 'locality__required') && errors.push('Please select locality');
      this.error.find((item) => item == 'locality__invalid') && errors.push('Selected locality is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    categoryErrors() {
      const errors = [];
      this.error.find((item) => item == 'category__required') && errors.push('Please select category');
      this.error.find((item) => item == 'category__invalid') && errors.push('Selected category is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    orgErrors() {
      const errors = [];
      this.error.find((item) => item == 'organizer__required') && errors.push('Please select trip organizer');
      this.error.find((item) => item == 'organizer__invalid') && errors.push('Selected trip organizer is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    depCityErrors() {
      const errors = [];
      this.error.find((item) => item == 'departure_city__required') && errors.push('Please enter departure city');
      this.error.find((item) => item == 'departure_city__invalid') && errors.push('Provided departure city is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    depAddressErrors() {
      const errors = [];
      this.error.find((item) => item == 'departure_address__required') && errors.push('Please enter departure address');
      this.error.find((item) => item == 'departure_address__invalid') && errors.push('Provided departure address is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    desCityErrors() {
      const errors = [];
      this.error.find((item) => item == 'destination_cities__required') && errors.push('Please enter destination city');
      this.error.find((item) => item == 'destination_cities__invalid') && errors.push('Provided destination city is not valid');
      this.error.find((item) => item == 'destination_cities__limit_exceeded') &&
        errors.push('Destination cities count is too big (up to 10 destinations allowed)');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    fromErrors() {
      const errors = [];
      this.error.find((item) => item == 'available_since__required') && errors.push('Please enter available since');
      this.error.find((item) => item == 'available_since__invalid') && errors.push('Provided available since is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    toErrors() {
      const errors = [];
      this.error.find((item) => item == 'available_till__required') && errors.push('Please enter available till');
      this.error.find((item) => item == 'available_till__invalid') && errors.push('Provided available till is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    desTitleEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_en__required') && errors.push('Please enter title');
      this.error.find((item) => item == 'title_en__invalid') && errors.push('Provided title is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    desTitleArErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_ar__invalid') && errors.push('Provided title is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    desShortEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'short_description_en__required') && errors.push('Please enter short description');
      this.error.find((item) => item == 'short_description_en__invalid') && errors.push('Provided short description is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    desShortArErrors() {
      const errors = [];
      this.error.find((item) => item == 'short_description_ar__invalid') && errors.push('Provided short description is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    desDesEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'description_en__required') && errors.push('Please enter description');
      this.error.find((item) => item == 'description_en__invalid') && errors.push('Provided description is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    desDesArErrors() {
      const errors = [];
      this.error.find((item) => item == 'description_ar__invalid') && errors.push('Provided description is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    vatOptionEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'vat_option__required') && errors.push('Please select VAT type');
      this.error.find((item) => item == 'vat_option__invalid') && errors.push('Selected VAT type is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    serviceFeeOptionEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'service_fee_option__required') && errors.push('Please select service fee type');
      this.error.find((item) => item == 'service_fee_option__invalid') && errors.push('Selected service fee type is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setTripsItem');
  },
};
</script>

<style lang="scss">
#app .trip-new {
  .v-input {
    max-width: 371px;
    width: 100%;
  }
}
</style>
